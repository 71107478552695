


















































































































































































































































































import { Component, Mixins, Ref, Vue } from 'vue-property-decorator';
import SectionHeader from '../shared/SectionHeader.vue';
import FullWidthTitle from '../shared/FullWidthTitle.vue';
import {email, required, numeric, minLength, maxLength} from 'vuelidate/lib/validators';
import { Validation } from 'vuelidate';
import { ValidationProperties, ValidationGroups } from 'vue/types/vue';
import InvalidForm from '../shared/InvalidForm.vue';
import { FieldStateMixin } from '../../utils/FieldStateMixin';
import FormAgreement from './FormAgreement.vue';
@Component({
  components: { FormAgreement, InvalidForm, FullWidthTitle, SectionHeader },
  mixins: [FieldStateMixin],
  validations: {
    form: {
      category: { required },
      firstName: { required },
      lastName: { required },
      age: { required },
      email: { required, email },
      phone: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      school: { required },
      reason: { required },
      experience: { required },
    },
  },
})
export default class AuditionForm extends Mixins(FieldStateMixin) {
  public form: AuditionFormModel = this.getEmptyForm();
  public $mq: string;
  public auditionOptions: Option[] = [
    {
      text: 'Audiții cor',
      value: 'cor',
    },
    {
      text: 'Audiții percuție',
      value: 'percussion',
    },
    {
      text: 'Audiții Blockflote',
      value: 'blockflote',
    },
  ];
  public experienceOptions: Option[] = [
    {
      text: 'Da',
      value: 'yes',
    },
    {
      text: 'Nu',
      value: 'no',
    },
  ];
  public $v: ValidationProperties<this> &
    ValidationGroups &
    Validation & {
      form: { [key: string]: Validation };
    };
  @Ref() public agreement!: FormAgreement & {
    touch: () => void;
    invalid: () => boolean;
    reset: () => void;
  };
  public busy: boolean = false;
  public serverError: boolean = false;
  public success: boolean = false;

  public submit() {
    this.$v.$touch();
    this.agreement.touch();
    if (!this.$v.$invalid && !this.agreement.invalid()) {
      this.busy = true;
      this.serverError = false;
      this.success = false;
      this.$http
        .post(this.$apiRoutes.signUps.audition, this.form)
        .then(() => {
          this.success = true;
          this.form = this.getEmptyForm();
        })
        .catch(() => (this.serverError = true))
        .finally(() => {
          this.agreement.reset();
          this.$v.$reset();
          this.busy = false;
          scrollTo(0, 0);
        });
    }
  }

  private getEmptyForm(): AuditionFormModel {
    return {
      category: null,
      firstName: null,
      lastName: null,
      age: null,
      email: null,
      phone: null,
      school: null,
      reason: null,
      experience: null,
      agreed: false,
    };
  }
}

interface AuditionFormModel {
  category: 'cor' | 'percussion' | 'blockflote';
  firstName: string;
  lastName: string;
  age: number;
  email: string;
  phone: string;
  school: string;
  reason: string;
  experience: string;
  agreed: boolean;
}

interface Option {
  text: string;
  value: string;
}
